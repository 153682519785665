import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type * as Banners from '@src/@types/banners';
import type StatusData from '@src/@types/StatusData';

import DataStatus from '../enums/DataStatus';

import type { IGlobalState } from '.';

type IState = StatusData<Banners.List.Resp>;

const initialState: IState = {
    data: {},
    status: DataStatus.Default,
};

export const setBanners = createAction<IState>('banners/set');

export const bannersReducer = createReducer<IState>({}, initialState).on(setBanners, (_state, payload) => payload);

const getBanners = (state: IGlobalState) => state.banners.data;

const getIsLoaded = (state: IGlobalState) => state.banners.status === DataStatus.Success;

export function useBanners(): IState['data'] {
    return useSelector<IGlobalState, IState['data']>(getBanners);
}

export function useIsLoaded(): boolean {
    return useSelector<IGlobalState, boolean>(getIsLoaded);
}
