import cn from 'classnames';
import type { NextComponentType } from 'next';
import type { NextWebVitalsMetric } from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import type { NextRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import type { IAbTestingInfo } from '@sravni/ab-testing-sdk/dist/types/node';
import type { ThemeNames } from '@sravni/design-system-theme';
import { ThemeName } from '@sravni/design-system-theme';
import { CookieClient, CookieServer } from '@sravni/growth/cookie';
import type { IDeviceInfo } from '@sravni/koa-utils/lib/middlewares/device';
import { CommonHead } from '@sravni/next-common-head';
import { Banner, BANNER_TYPES } from '@sravni/react-advertisement';
import { Logo } from '@sravni/react-design-system';
import { SupportChannelType } from '@sravni/react-footer';
import { AbTestingProvider, DeviceInfoProvider, ThemeProvider } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import { AppHeader } from '@src/components/AppHeader';
import { MobileAppBanner } from '@src/components/MobileAppBanner';
import { NoScript } from '@src/components/NoScript';
import SeoHead from '@src/components/Seo/SeoHead';
import { SpecialPageDisclaimer } from '@src/components/SpecialPageDisclaimer';
import { CloseButton } from '@src/components/Toast';
import { BANNER_TYPE_TOP } from '@src/constants/banners';
import { DEFAULT_REGION_ROUTE } from '@src/constants/filters';
import { Page } from '@src/constants/page';
import { PRELOAD_FONTS } from '@src/constants/preloadFonts';
import { getGtmOptions } from '@src/helpers/getGtmOptions';
import { checkTrailingSlash } from '@src/helpers/handleRoutes';
import { sendVisitVitrinEvent } from '@src/helpers/mindbox/mindboxEvents';
import { factoryCookie } from '@src/modules/cookie';
import type { IGlobalState } from '@src/reducers';
import { setHistory } from '@src/reducers/history';
import { currentLocationSelector, fetchRegions } from '@src/reducers/locations';
import { isProduction, isServer } from '@src/utils';
import { checkIsNeededToSendVisitMindboxEvent } from '@src/utils/checkForSendMindboxVisitEvent';
import {
    isAppLandingPage,
    isFullDealLandingPage,
    isKZLandingPage,
    isLandingPage,
    isMainPage,
    isMfoAndroidPage,
    isNaKartuPage,
    isNewSpecialPage,
    isPartner2GisPage,
    isPromoTermsPage,
    isPromotionPageWithoutQuery,
    isSpecialPageWithoutQuery,
    isWhiteLabelPage,
} from '@src/utils/routing';

import ConfigProvider from '../providers/config';
import { sendWebVitals } from '../services/webVitals';
import { getOrCreateStore } from '../store/getOrCreateStore';
import { initialDispatcher } from '../store/initialDispatcher';
import styles from '../styles/styles.module.scss';
import queue from '../utils/queue';

// eslint-disable-next-line import/no-unassigned-import
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-unassigned-import
import '../styles/global.scss';
// eslint-disable-next-line import/no-unassigned-import
import '@sravni/design-system-theme/lib/globalStyles.css';

const Footer = dynamic<any>(() => import('@sravni/react-footer').then((mod) => mod.Footer));
const ToastContainer = dynamic(
    // @ts-ignore
    () => import('react-toastify').then((mod) => mod.ToastContainer),
);

interface IAppProps {
    Component: NextComponentType;
    pageProps: Record<string, unknown>;
    router: NextRouter;
    initialReduxState: IGlobalState;
    mediaInfo: IDeviceInfo;
    pageType: string;
    theme: ThemeNames;
    abTestingInfo?: IAbTestingInfo;
}

const WEBPUSH_DELAY = 7000;

// eslint-disable-next-line max-statements
const MyApp = (props: IAppProps) => {
    const { Component, pageProps, router, initialReduxState, mediaInfo, pageType, abTestingInfo, theme } = props;
    const reduxStore = getOrCreateStore(initialReduxState);

    const { getState, dispatch } = reduxStore;
    const { user, history, siteSettings, banners, route } = getState();
    const { urls = [] } = history;
    const { asPath, query } = router;
    const subId = user.loggedIn ? user.account?.sub : undefined;
    const [pathWithoutQuery] = asPath.split('?');
    const isNeededToSendVisitMindboxEvent = checkIsNeededToSendVisitMindboxEvent(pathWithoutQuery);

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);

    useEffect(() => {
        if (!isProduction) {
            return;
        }

        const { publicRuntimeConfig } = getConfig();

        queue.push(() => {
            import('@sentry/browser').then((Sentry) => {
                Sentry.init({
                    dsn: publicRuntimeConfig.sentryDSN,
                    release: publicRuntimeConfig.release,
                    environment: publicRuntimeConfig.environment,
                });
            });
        });

        queue.push(() => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker
                    .register('/service-worker.js')
                    .then(() => {
                        setTimeout(() => {
                            if (typeof window.mindbox === 'undefined') {
                                return;
                            }

                            window.mindbox('webpush.subscribe', {
                                onGranted: () => undefined,
                                onDenied: () => undefined,
                            });
                        }, WEBPUSH_DELAY);
                        if (isNeededToSendVisitMindboxEvent) {
                            sendVisitVitrinEvent(pathWithoutQuery);
                        }
                    })
                    .catch((error) => {
                        console.error('Error during service worker registration:', error);
                    });
            }
        });
    }, [isNeededToSendVisitMindboxEvent, pathWithoutQuery]);

    useEffect(() => {
        if (urls[urls.length - 1] !== asPath) {
            dispatch(setHistory([asPath]));
        }
    }, [dispatch, asPath, urls]);

    const isWhiteLabel = isWhiteLabelPage(asPath);
    const isLanding = isLandingPage(asPath);
    const isPartnerPage = pageType === Page.PARTNERS_PAGE;
    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isPromotion = isPromotionPageWithoutQuery(asPath);
    const isPromoTerms = isPromoTermsPage(asPath);
    const isAppLanding = isAppLandingPage(asPath);
    const isKZLanding = isKZLandingPage(asPath);
    const isMfoAndroidLanding = isMfoAndroidPage(asPath);
    const isNewSpecial = isNewSpecialPage(asPath);
    const isFullDealLanding = isFullDealLandingPage(asPath);
    const isNaKartu = isNaKartuPage(asPath);
    const isMain = isMainPage(asPath, query);
    const isPartner2Gis = isPartner2GisPage(asPath);

    const analyticsOptions = getGtmOptions({
        userId: subId,
        isWhiteLabel,
        asPath,
        theme,
    });

    const footerSupport = {
        ...siteSettings.data.footer?.support,
        channels: [
            {
                type: SupportChannelType.TELEGRAM,
                link: 'https://t.me/SravniMFOSupport_bot',
            },
            {
                type: SupportChannelType.PHONE,
                link: '88007073902',
            },
            {
                type: SupportChannelType.EMAIL,
                link: 'mfo_support@sravni.ru',
            },
            {
                type: SupportChannelType.WHATSAPP,
                link: 'https://wa.me/79252943371',
            },
        ],
    };

    const isHeaderAndFooterShowed =
        !isWhiteLabel &&
        !isPartnerPage &&
        !isNewSpecial &&
        !isSpecial &&
        !isPromoTerms &&
        !isAppLanding &&
        !isMfoAndroidLanding &&
        !isPartner2Gis;

    const mode = isLanding || isNewSpecial || isSpecial || isKZLanding || isPartner2Gis ? 'landing' : 'default';

    const isPageOnlyForMobile = isFullDealLanding;

    return (
        <Provider store={reduxStore}>
            <DeviceInfoProvider initialInfo={mediaInfo}>
                <AbTestingProvider initialValue={abTestingInfo} mode="uncontrolled">
                    <ThemeProvider initialTheme={theme}>
                        <ConfigProvider>
                            <CommonHead
                                analyticsOptions={analyticsOptions}
                                // @ts-ignore
                                designSystem="custom" // хак, чтобы не подтягивались все дефолтные шрифты, зашитые в CommonHead
                                preloadedFonts={PRELOAD_FONTS}
                            />
                            <NoScript isWhiteLabel={isWhiteLabel} />
                            <SeoHead />
                            <ToastContainer hideProgressBar closeButton={<CloseButton />} limit={1} />
                            <div className={cn({ [styles.containerForMobile]: isPageOnlyForMobile })}>
                                <div
                                    className={cn(styles.container, {
                                        slimContent: isPromotion,
                                    })}
                                >
                                    <Banner
                                        abName=""
                                        banner={banners.data?.[BANNER_TYPE_TOP]}
                                        type={BANNER_TYPES.TOP}
                                    />
                                    {!route.hasUtmLabel && <MobileAppBanner />}
                                    {isHeaderAndFooterShowed && <AppHeader mode={mode} />}
                                    {(isSpecial ||
                                        isNewSpecial ||
                                        isPartner2Gis ||
                                        (isPromotion && !mediaInfo.phone)) && (
                                        <div
                                            className={cn(styles.specialLogoContainer, {
                                                [styles.headerMargin]: isPromotion,
                                            })}
                                        >
                                            <div className={styles.specialLogo}>
                                                <Logo />
                                            </div>
                                        </div>
                                    )}
                                    <Component {...pageProps} />
                                </div>
                                {(isSpecial || isPartner2Gis) && <SpecialPageDisclaimer />}
                                {isHeaderAndFooterShowed && (
                                    <Footer
                                        className={styles.footer}
                                        mode={mode}
                                        support={footerSupport}
                                        menuLinks={siteSettings.data.footer?.menu}
                                        socialLinks={siteSettings.data.footer?.socialLinks}
                                    />
                                )}
                            </div>
                        </ConfigProvider>
                    </ThemeProvider>
                </AbTestingProvider>
            </DeviceInfoProvider>
        </Provider>
    );
};

MyApp.getInitialProps = async ({ Component, ctx }: Application.ReduxAppContext) => {
    if (isServer) checkTrailingSlash(ctx);

    ctx.cookie = isServer && ctx.res && ctx.req ? new CookieServer({ req: ctx.req, res: ctx.res }) : new CookieClient();

    const [pathname] = ctx.asPath?.split('?') || [];

    ctx.store = initialDispatcher(ctx, getOrCreateStore());

    factoryCookie(ctx.cookie);

    const location = currentLocationSelector(ctx.store.getState());

    let deviceInfo: IDeviceInfo = { phone: false, tablet: false, isAppleMobile: false };
    let theme: ThemeNames = ThemeName.lager;

    if (ctx.req?.__DEVICE_INFO__) {
        deviceInfo = ctx.req.__DEVICE_INFO__;
    }

    ctx.params = {
        locationRoute: location?.route || DEFAULT_REGION_ROUTE,
        pathname,
        mediaInfo: deviceInfo,
    };

    if (ctx.req?.__THEME__) {
        theme = ctx.req.__THEME__;
    }

    const initialPageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : ({} as any);
    const abTestingInfo = isServer && ctx.req.__AB_TESTING__ ? ctx.req.__AB_TESTING__ : undefined;

    return {
        pageProps: initialPageProps,
        initialReduxState: ctx.store.getState(),
        mediaInfo: ctx.params.mediaInfo,
        pageType: ctx.req?.__PAGE_TYPE__,
        theme,
        abTestingInfo,
    };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
    sendWebVitals(metric);
}

export default MyApp;
