import { AMOUNT_SETTINGS, ANY_VALUE, FILTERS_NAMES, TERM_SETTINGS } from '../constants/filters';

import { checkValueInRange } from './validate';

const WALLETS = {
    ANY: 'any-e-wallet',
    QIWI: 'qiwi-wallet',
    YANDEX: 'yandex-money',
};

// временная подмена этих методов оплаты, пока их не присвоят продукту
const TEMP_NOT_WORKING_METHODS = [
    'sberbank-card',
    'maestro',
    'card-no-name',
    'kukuruza',
    'card-virtual',
    'any-remittance',
    'online-bank',
    'mir',
];

const CREDIT_SERVICE_OBTAINING_METHODS_FILTER_KEY = 'obtainingMethods';

const mapObtainingMethod = (method: string): string[] =>
    TEMP_NOT_WORKING_METHODS.includes(method) ? ['card-or-account'] : [method];

const getObtainingMethods = (value: string): string[] => {
    if ([WALLETS.ANY, WALLETS.YANDEX].includes(value)) {
        return Object.values(WALLETS);
    }

    if (value === ANY_VALUE) {
        return [];
    }

    return mapObtainingMethod(value);
};

export const filterQueryParams = <T extends {}>(query: T) =>
    Object.entries(query).reduce<Record<string, unknown>>((acc, [key, value]) => {
        if (value === undefined) {
            return acc;
        }

        if (key === FILTERS_NAMES.OBTAINING_METHOD) {
            acc[CREDIT_SERVICE_OBTAINING_METHODS_FILTER_KEY] = getObtainingMethods(value as string);
        } else if (key === FILTERS_NAMES.AMOUNT) {
            acc[key] = checkValueInRange(value as number, { min: AMOUNT_SETTINGS.min, max: AMOUNT_SETTINGS.max });
        } else if (key === FILTERS_NAMES.TERM) {
            acc[key] = checkValueInRange(value as number, { min: TERM_SETTINGS.min, max: TERM_SETTINGS.max });
        } else if (key === FILTERS_NAMES.REGISTRATION_WAY && value === ANY_VALUE) {
            return acc;
        } else if (key === FILTERS_NAMES.AGE && value === ANY_VALUE) {
            return acc;
        } else if (key === FILTERS_NAMES.EXCLUDE_ORGANIZATIONS_IDS_FRONT) {
            acc[FILTERS_NAMES.EXCLUDE_ORGANIZATIONS_IDS_SERVICE] = (value as string).split(',');
        } else if (key === FILTERS_NAMES.INCLUDE_PRODUCT_IDS) {
            if (value !== '') {
                acc[FILTERS_NAMES.INCLUDE_PRODUCT_IDS] = (value as string).split(',');
            }
        } else {
            acc[key] = value;
        }

        return acc;
    }, {});
