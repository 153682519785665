import qs from 'querystring';

import type { NextRouter } from 'next/router';
import Router from 'next/router';

import { getRouteParams } from '../helpers/query';

const paramRegExp = /(:[^\/&\?]*\??)(\/|$)/g;

const escapeRegExp = (str: string) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterValues = <TType extends { [k: string]: any }>(
    predicate: (key: string, value: string) => boolean,
    params: TType,
): Partial<TType> =>
    Object.entries(params).reduce((acc, [k, v]) => (predicate(k, v) ? Object.assign(acc, { [k]: v }) : acc), {});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toString = (params: Record<string, any>, prefix = '?') => {
    const queryString = qs.stringify(params);

    return queryString ? prefix + queryString : '';
};

const replaceWithParams = (pattern: string, params: Record<string, string>) =>
    Object.keys(params)
        .reduce(
            (acc, param) =>
                acc.replace(new RegExp(`:${escapeRegExp(param)}\\??(?=(\\/|$|\\?))`), String(params[param])),
            pattern,
        )
        .replace(paramRegExp, '');

const addInitialSlash = (str: string) => (str.match(/^\//) ? str : `/${str}`);

interface ILinkParams {
    organizationAlias?: string;
    productAlias?: string;
}

export const createLinkProps = (page = '', pattern = '', params: ILinkParams = {}) => {
    const inlineParams = filterValues((key) => !!pattern.match(`:${key}`), params);

    return {
        href: `${addInitialSlash(page)}${toString(inlineParams)}`,
        as: `${replaceWithParams(pattern, inlineParams)}`,
    };
};

export const easyQueryReplace = (router: NextRouter, query: IQueryParams) => {
    const routerParams = getRouteParams(router.query);
    const [asPath] = router.asPath.split('?');
    const url = `${router.route}${toString({ ...routerParams, ...query })}`;
    const as = `${asPath}${toString(query)}`;

    return Router.replace(url, as, { scroll: false });
};

export const preparePath = (route: string) => {
    const [pathname] = route.split('?');
    return pathname.endsWith('/') ? pathname : `${pathname}/`;
};

const replaceDoubleSlash = (str: string) => str.replace(/\/{2,}/g, '/');

export const prepareSeoUrl = (pathname: string, paramRegion?: string) => {
    const url = preparePath(pathname);

    if (!paramRegion) {
        return url;
    }

    return replaceDoubleSlash(url.replace(paramRegion, ''));
};

interface IQueryParams {
    paramRegion?: string;
}

const getNonRegionUrl = (path: string, query: IQueryParams) => {
    const { paramRegion } = query;
    const prepared = preparePath(path);

    return paramRegion ? replaceDoubleSlash(prepared.replace(paramRegion, '')) : prepared;
};

export const isMainPage = (path: string, query: IQueryParams): boolean => {
    const testUrl = getNonRegionUrl(path, query);

    return /^\/zaimy\/?$/.test(testUrl);
};

export const isSpecialPage = (path: string, query: IQueryParams): boolean => {
    const testUrl = getNonRegionUrl(path, query);

    return /^\/zaimy\/special\/?$/.test(testUrl);
};

export const isWhiteLabelPage = (path: string): boolean => /wl_app\/?/.test(path);
export const isWhiteLabelDeniedPage = (path: string): boolean =>
    path.includes('/wl_app/other/') || path.includes('/wl_app/other_web/');

export const isPromoTermsPage = (path: string): boolean => /terms\/?/.test(path);

export const isLandingPage = (path: string): boolean => /lushie_zaimy_61_dney_i_bolee\/?/.test(path);

export const isSpecialPageWithoutQuery = (path = ''): boolean => path.includes('/special/');

export const isPromotionPageWithoutQuery = (path?: string): boolean => /akcii\/?/.test(path || '');

export const isAppLandingPage = (path: string): boolean => /\/app\//.test(path);

export const isKZLandingPage = (path: string): boolean => /kz\/zaimy\/?/.test(path);

export const isMfoAndroidPage = (path: string): boolean => path === '/promo/mfo_android/';

export const isNaKartuPage = (path: string): boolean => /\/na-kartu(\/|$)/.test(path);

export const isNewSpecialPage = (path: string): boolean => path.includes('/new_special/');

export const isFullDealLandingPage = (path: string): boolean => path.includes('/full-deal-offer/');

export const isPartner2GisPage = (path: string): boolean => path.includes('/partners/2gis/');
