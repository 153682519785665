import { useLayoutEffect, useState } from 'react';

import { Button, Icon, Typography } from '@sravni/react-design-system';
import { Cross } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import { UNBREAKABLE_SEPARATOR } from '@src/constants/unbreakableSeparator';

import styles from './styles.module.scss';

const MOBILE_APP_BANNER_LOCAL_STORAGE_KEY = 'showMobileAppBanner';
const APP_URL =
    'https://sravni.onelink.me/cyn1?pid=sravni.ru&af_channel=display&c=mfo_loading_app&af_adset=zaimy&is_retargeting=true&deep_link_value=https%3A%2F%2Fwww.sravni.ru%2Fmobile%2F%3F%26action%3D%7B%22type%22%3A%22remoteAction%22%2C%22params%22%3A%7B%22url%22%3A%22mfo%2Faction%22%7D%2C%22requestParams%22%3A%7B%22_serialized%22%3A%20%22%7B%5C%22placement%5C%22%3A%5C%22%D0%94%D0%B8%D0%BF%D0%BB%D0%B8%D0%BD%D0%BA%5C%22%7D%22%7D%2C%22analyticId%22%3A%22screenOpenedFromDeeplink%22%7D%26af_android_store_csl%3Daf_android_store_csl%26af_reengagement_window%3D30d';

export const MobileAppBanner = () => {
    const [visible, setVisible] = useState(false);

    const isMobile = useIsMobile();

    useLayoutEffect(() => {
        if (!isMobile) {
            return;
        }

        setVisible(localStorage?.getItem(MOBILE_APP_BANNER_LOCAL_STORAGE_KEY) !== 'false');
    }, [isMobile]);

    if (!visible) {
        return null;
    }

    const handleClose = () => {
        localStorage.setItem(MOBILE_APP_BANNER_LOCAL_STORAGE_KEY, 'false');
        setVisible(false);
    };

    const handleDownloadApp = () => {
        handleClose();
        window.open(APP_URL, '_blank');
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.leftContent}>
                    <Icon onClick={handleClose} icon={<Cross />} size={24} />

                    <Typography.Heading level={5} className={styles.title}>
                        {`Вероятность одобрения выше в${UNBREAKABLE_SEPARATOR}приложении Сравни`}
                    </Typography.Heading>
                </div>

                <Button onClick={handleDownloadApp} size={36} className={styles.button}>
                    Скачать
                </Button>
            </div>
        </div>
    );
};
