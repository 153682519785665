import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DesktopHeader } from '@sravni/react-header/lib/DesktopHeader';
import { MobileHeader } from '@sravni/react-header/lib/MobileHeader';
import { GlobalSearch } from '@sravni/react-search/lib/GlobalSearch';
import { useIsMobile } from '@sravni/react-utils';
import type { IPublicUser } from '@sravni/types/lib/auth';

import { getFormatPath } from '@src/helpers/getFormatPath';
import type { IGlobalState } from '@src/reducers';
import { useUser } from '@src/reducers/user';
import { isSpecialPageWithoutQuery, isFullDealLandingPage } from '@src/utils/routing';

import styles from './styles.module.scss';

type Props = {
    mode: 'landing' | 'default';
};

export const AppHeader = ({ mode }: Props) => {
    const isMobile = useIsMobile();
    const siteSettings = useSelector((state: IGlobalState) => state.siteSettings.data);
    const { centers, currentLocation } = useSelector((state: IGlobalState) => state.locations);
    const user = useUser();
    const { asPath } = useRouter();
    const formatPath = useMemo(() => getFormatPath(asPath, currentLocation.alias), [currentLocation.alias, asPath]);

    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isFullDealLanding = isFullDealLandingPage(asPath);

    const searchUrl = getConfig().publicRuntimeConfig.publicUrl;

    const Header = (isMobile && !isSpecial) || isFullDealLanding ? MobileHeader : DesktopHeader;

    return (
        <Header
            serviceURL={asPath}
            regions={centers}
            currentRegion={currentLocation}
            settings={siteSettings.header}
            user={user as IPublicUser}
            formatPath={formatPath}
            mode={mode}
            className={isMobile && isSpecial ? styles.header : undefined}
            enableTheme
            search={<GlobalSearch searchServiceHost={searchUrl} asPath={asPath} />}
        />
    );
};
