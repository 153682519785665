import { formatUserData } from '@sravni/utils/lib/auth';

import type { Application } from '@src/@types/app';
import { setBanners } from '@src/reducers/banners';
import { setBranding } from '@src/reducers/branding';
import { setupDictionaries } from '@src/reducers/dictionaries';
import { setHeaderState } from '@src/reducers/header';
import { setCurrentLocation } from '@src/reducers/locations';
import { setAbTestingInfo } from '@src/reducers/metadata';
import { setMicroCredits } from '@src/reducers/microcredits';
import { setMicrocreditUserDeals } from '@src/reducers/microcreditUserDeals';
import { setOrganizations } from '@src/reducers/organizations';
import { setReviews } from '@src/reducers/reviews';
import { setRouteState } from '@src/reducers/route';
import { setSeoData, setSeoFilters } from '@src/reducers/seo';
import { setSettings } from '@src/reducers/siteSettings';
import { authorizeUser } from '@src/reducers/user';

import type { ReduxStore } from './types';

// eslint-disable-next-line max-statements
export const initialDispatcher = (context: Application.ReduxNextPageContext, store: ReduxStore) => {
    if (typeof window !== 'undefined' || !context.req) {
        return store;
    }

    if (context.req.__USER__) {
        store.dispatch(authorizeUser(formatUserData(context.req.__USER__)));
    }

    if (context.req.__SELECTED_LOCATION__) {
        store.dispatch(setCurrentLocation(context.req.__SELECTED_LOCATION__));
    }

    if (context.req.__SITE_SETTINGS__) {
        store.dispatch(setSettings(context.req.__SITE_SETTINGS__));
    }

    if (context.req.__DICTIONARIES__) {
        store.dispatch(setupDictionaries(context.req.__DICTIONARIES__));
    }

    if (context.req.__BANNERS__) {
        store.dispatch(setBanners(context.req.__BANNERS__));
    }

    if (context.req.__BRANDING__) {
        store.dispatch(setBranding(context.req.__BRANDING__));
    }

    if (context.req.__REVIEWS__) {
        store.dispatch(setReviews(context.req.__REVIEWS__));
    }

    if (context.req.__ROUTE__) {
        store.dispatch(setRouteState(context.req.__ROUTE__));
    }

    if (context.req.__HEADER__) {
        store.dispatch(setHeaderState(context.req.__HEADER__));
    }

    if (context.req.__SEO_FILTERS__) {
        store.dispatch(setSeoFilters(context.req.__SEO_FILTERS__));
    }

    if (context.req.__SEO__) {
        store.dispatch(setSeoData(context.req.__SEO__));
    }

    if (context.req.__OFFERS__) {
        const { items, metadata } = context.req.__OFFERS__.data;
        store.dispatch(setMicroCredits({ lists: items, metadata }));
    }

    if (context.req.__ORGANIZATIONS__) {
        store.dispatch(setOrganizations(context.req.__ORGANIZATIONS__));
    }

    if (context.req.__AB_TESTING__) {
        store.dispatch(setAbTestingInfo(context.req.__AB_TESTING__));
    }

    if (context.req.__MICROCREDIT_USER_DEALS__) {
        store.dispatch(setMicrocreditUserDeals(context.req.__MICROCREDIT_USER_DEALS__));
    }

    return store;
};
