import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { IGlobalState } from './index';

interface IState {
    urls?: string[];
}

const initialState: IState = {
    urls: [],
};

export const setHistory = createAction<IState['urls']>('set history');

export const historyReducer = createReducer<IState>({}, initialState).on(setHistory, (state, payload = []) => {
    let newHistory = state.urls || [];

    if (newHistory.length === 2) {
        newHistory = newHistory.slice(1);
    }

    return {
        ...state,
        urls: [...newHistory, ...payload],
    };
});

const getHistory = (state: IGlobalState): IState => state.history;
const getHistoryUrls = (state: IGlobalState): IState['urls'] => getHistory(state).urls;

function useHistoryUrls(): IState['urls'] {
    return useSelector<IGlobalState, IState['urls']>(getHistoryUrls);
}

export function useHistoryPreviousLocation(): string {
    const urls = useHistoryUrls();

    if (urls?.length === 2) {
        return urls[0];
    }

    return '';
}
