import { useSelector } from 'react-redux';
import { createReducer, createAction } from 'redux-act';

import IRoute from '@src/@types/IRoute';
import { IGlobalState } from '@src/reducers/index';

const initialState: IRoute = {
    hasUtmLabel: undefined,
};

export const setRouteState = createAction<IRoute>('route/set');

export const routeReducer = createReducer({}, initialState).on(setRouteState, (_, data) => data);

export const getHasUtmLabel = (state: IGlobalState) => state.route.hasUtmLabel;

export function useHasUtmLabel() {
    return useSelector(getHasUtmLabel);
}
