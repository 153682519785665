import type { IFAQMeta, Seo } from '@sravni/types/lib/seo';

import type { List } from '@src/@types/microcredits';
import {
    BASE_URL,
    DEFAULT_BREADCRUMB,
    FAQ_ITEM_TEMPLATE,
    FAQ_TEMPLATE,
    MIN_AMOUNT,
    OFFER_TEMPLATE,
    ORGANIZATION_TEMPLATE,
} from '@src/components/Seo/consts';
import type { Faq, FaqItem, Offer, Organization, Product } from '@src/components/Seo/types';
import { getProductTemplate } from '@src/components/Seo/utils/getProductTemplate';
import { AMOUNT_SETTINGS } from '@src/constants/filters';
import type { ILocation } from '@src/reducers/locations';
import { getCommonReplacements } from '@src/utils/meta';
import { safeJSONParse } from '@src/utils/safeJSONParse';

interface IParams {
    seo: Partial<Seo>;
    microcredits: List.IStoreData;
    currentLocation: ILocation;
    metadata?: List.IMetadata;
}

export const generateJsonLdForListPage = ({ seo, microcredits, currentLocation, metadata }: IParams): string => {
    const productTemplate = getProductTemplate();

    const template = [productTemplate, ORGANIZATION_TEMPLATE];
    const strTemplate = JSON.stringify(template)
        .replace(/{main.title}/g, seo.heading || '')
        .replace(
            '{page.description}',
            getCommonReplacements({ template: seo.description || '', meta: metadata, location: currentLocation }),
        )
        .replace('{min.amount}', MIN_AMOUNT)
        .replace('{max.amount}', AMOUNT_SETTINGS.max.toString())
        .replace('{MFO.count}', microcredits?.count?.toString() || '');

    const newTemplate = safeJSONParse<[Product, Organization]>(strTemplate);

    if (!newTemplate) return '';

    if (seo.breadcrumbs) {
        newTemplate[1].breadcrumb.numberOfItems = seo.breadcrumbs.length;
    }

    const breadcrumbs =
        seo.breadcrumbs?.reduce(
            (acc, { title, url }, index) => [
                ...acc,
                {
                    '@type': 'ListItem',
                    position: index + 2,
                    name: title,
                    item: `${BASE_URL}${url}`,
                },
            ],
            [DEFAULT_BREADCRUMB],
        ) ?? [];

    newTemplate[1].breadcrumb.itemListElement.push(...breadcrumbs);

    microcredits?.items?.forEach(({ name, organization, termRange, amountRange, rateRange }) => {
        let strOfferTemplate = JSON.stringify(OFFER_TEMPLATE)
            .replace('{MFO.name}', name)
            .replace('{MFO.organization.name}', organization.name);

        if (termRange.fromValue) {
            strOfferTemplate = strOfferTemplate.replace(
                '{MFO.termRange.fromValue}',
                (termRange.fromValue / 60 / 24).toString(),
            );
        }

        if (termRange.toValue) {
            strOfferTemplate = strOfferTemplate.replace(
                '{MFO.termRange.toValue}',
                (termRange.toValue / 60 / 24).toString(),
            );
        }

        if (rateRange.from) {
            strOfferTemplate = strOfferTemplate.replace('{MFO.rateRange.from}', rateRange.from.toString());
        }

        if (amountRange.from) {
            strOfferTemplate = strOfferTemplate.replace('{MFO.amountRange.from}', amountRange.from.toString());
        }

        if (amountRange.to) {
            strOfferTemplate = strOfferTemplate.replace('{MFO.amountRange.to}', amountRange.to.toString());
        }

        const parsedOfferTemplate = safeJSONParse<Offer>(strOfferTemplate);

        if (parsedOfferTemplate) {
            newTemplate[0].offers.offers.push(parsedOfferTemplate);
        }
    });

    const faq = seo.contentBlocks?.find((value): value is IFAQMeta => value.type === 'faq');

    /** if we got faq we insert it on a second place in array */
    if (faq?.serviceResponseData?.items?.length) {
        FAQ_TEMPLATE.mainEntity = faq.serviceResponseData.items.reduce<FaqItem[]>((acc, { question, answer }) => {
            const newFaqItemTemplate = {
                ...FAQ_ITEM_TEMPLATE,
                name: question,
                acceptedAnswer: {
                    ...FAQ_ITEM_TEMPLATE.acceptedAnswer,
                    text: answer,
                },
            };
            return [...acc, newFaqItemTemplate];
        }, []);

        ((newTemplate as unknown) as [Product, Faq, Organization]).splice(1, 0, FAQ_TEMPLATE);
    }

    return JSON.stringify(newTemplate);
};
